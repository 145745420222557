<template>
  <div>
    <v-btn icon color="primary" @click="$emit('click:camera')" v-if="camera">
      <v-icon>mdi-camera</v-icon>
    </v-btn>
    <v-badge bottom :content="count" overlap :value="count">
      <base-prop-tooltip :tooltip="tooltip" :color="value ? 'primary' : ''">
        <v-btn @click="$emit('input', !value)" icon x-small class="mr-2" :to="to">
          <v-icon size="18"> {{ iconComputed }} </v-icon>
        </v-btn>
      </base-prop-tooltip>
    </v-badge>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    to: {
      type: String,
      default: ''
    },
    camera: {
      type: Boolean,
      default: false
    },
    count: {
      type: [Number, String],
      default: 0
    },
    tooltip: {
      type: String,
      default: 'Gallery'
    }
  },
  computed: {
    iconComputed() {
      if (this.value) {
        return '$gallery';
      }
      return '$galleryGrey';
    }
  }
};
</script>

<style>
</style>
